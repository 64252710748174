import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { TaskService } from '../services';
import TasksFiltersVisualStore from './TasksFiltersVisualStore';
import { ErrorStore, NotificationStore, ProjectsStore, TabsStore, UserProfileStore } from '../../common/stores';
import { ALL_PROJECTS } from '../screens/TasksPage';
import { TaskListModel, TaskModel, TaskPrivacyType, TasksData } from '../types';
import { MetadataDefinition } from '../../administration/types/Metadata';
import { GlobalAdministrationService } from '../../administration/services/GlobalAdministrationService';
import { ColumnsFilter, PinnedTask, UserProfile } from '../../common/services/types';
import TasksGridVisualStore from './TasksGridVisualStore';
import TaskCreateVisualStore from './TaskCreateVisualStore';
import TaskViewVisualStore from './TaskViewVisualStore';
import { TaskStatus } from '../../task_statuses/types';
import { ActionService } from '../../administration/services/ActionService';
import { ActionDefinition } from '../../administration/types/Actions';
import ClientActionRunner from '../../administration/misc/ClientActionHandler';
import { TaskType } from '../../task_types/types';
import TaskTypesService from '../../task_types/services/TaskTypesService';

export default class TasksRootStore {
    tasksGlobalViewVisualStore: TaskViewVisualStore;

    tasksFiltersVisualStore: TasksFiltersVisualStore;

    tasksGridVisualStore: TasksGridVisualStore;

    taskCreateVisualStore: TaskCreateVisualStore;

    tasks: TaskListModel[] = [];

    tasksLoading: boolean = false;

    metadataDefinitions: MetadataDefinition[] = [];

    metadataDefinitionsLoading: boolean = false;

    actionDefinitions: ActionDefinition[] = [];

    actionDefinitionsLoading: boolean = false;

    taskTypes: TaskType[] = [];

    taskStatuses: TaskStatus[] = [];

    allUsersFullNameResolver: { [id: string]: string } = {};

    userProfiles: UserProfile[];

    usersLoaded: boolean = false;

    loadingUsers: boolean = false;

    usersInProject: UserProfile[] = [];

    userProfilePictures: { [id: string]: string | undefined } = {};

    tasksCount: number;

    pageIsLoading: boolean = true;

    layoutIsLoading: boolean;

    constructor(
        private tasksService: TaskService,
        private tasksTypesService: TaskTypesService,
        private projectsStore: ProjectsStore,
        tabsStore: TabsStore,
        private errorStore: ErrorStore,
        private adminService: GlobalAdministrationService,
        private actionService: ActionService,
        private userProfileStore: UserProfileStore,
        notificationStore: NotificationStore
    ) {
        this.tasksGlobalViewVisualStore = new TaskViewVisualStore(tasksService, tabsStore, errorStore, userProfileStore, notificationStore, this, new ClientActionRunner());
        this.tasksFiltersVisualStore = new TasksFiltersVisualStore(this);
        this.tasksGridVisualStore = new TasksGridVisualStore(this, errorStore, tasksService);
        this.taskCreateVisualStore = new TaskCreateVisualStore(projectsStore, errorStore, tasksService, tasksTypesService, adminService, this);
        this.getTaskListLayout();
        makeObservable<TasksRootStore,
        'loadAllUsers'
        >(this, {
            metadataDefinitions: observable,
            metadataDefinitionsLoading: observable,
            actionDefinitions: observable,
            actionDefinitionsLoading: observable,
            taskTypes: observable,
            loadingUsers: observable,
            tasks: observable,
            tasksCount: observable,
            usersInProject: observable,
            layoutIsLoading: observable,
            loadTaskTypes: action.bound,
            columnsFilterDict: computed,
            projects: computed,
            allUsersFullNameResolver: observable,
            usersLoaded: observable,
            userProfilePictures: observable,
            tasksLoading: observable,
            pageIsLoading: observable,
            loadAllUsers: action.bound,
            updateField: action.bound,
            getUserProfilePicture: action.bound,
            loadUsersForProject: action.bound,
            loadActionDefinitions: action.bound,
            resetAllFilters:  action.bound,
            setLayoutIsLoading: action.bound,
            setTasksIsLoading: action,
            setPageIsLoading: action,
            isGridView: computed
        });

        this.loadAllUsers();
        this.getTaskStatuses();
        this.loadActionDefinitions();
    }


    get selectedProject() {
        return this.tasksGridVisualStore.selectedProject;
    }

    get taskTypesForCurrentProject() {
        return this.selectedProject === ALL_PROJECTS ? this.taskTypes : this.taskTypes.filter(t => t.projectId === this.selectedProject);
    }

    get taskStatusesForCurrentProject() {
        const types = this.selectedProject === ALL_PROJECTS ? this.taskTypes : this.taskTypes.filter(t => t.projectId === this.selectedProject);
        return this.taskStatuses.filter(t => types.map(x => x.id).includes(t.typeId));
    }

    get columnsFilterDict() {
        return this.tasksFiltersVisualStore.columnsFilterDict;
    }

    get currentUserId() {
        return this.userProfileStore.userProfile?.userId;
    }

    get taskPreview() {
        return this.tasksGlobalViewVisualStore.taskPreview;
    }

    get projects() {
        return this.projectsStore.projects;
    }

    get isGridView() {
        return this.tasksGridVisualStore.isGridView;
    }
    
    getProjectName = (id: string) => {
        return this.projectsStore.projects.find(x => x.id === id)!.name;
    };

    getTaskName = (id: string) => {
        return this.taskTypes.find(x => x.id === id)?.name;
    };

    getTaskSatusesByProjectId (id: string) {
        const types = this.taskTypes.filter(t => t.projectId === id);
        return this.taskStatuses.filter(t => types.map(x => x.id).includes(t.typeId));
    }

    getActionName(definitionId: string) {
        const def = this.actionDefinitions.find(x => x.id === definitionId);
        return def ? (def.title ?? def.name) : 'Unknown action';
    }

    setTasksIsLoading(val: boolean) {
        this.tasksLoading = val;
    }
    
    setPageIsLoading(val: boolean) {
        this.pageIsLoading = val;
    }

    setLayoutIsLoading(val: boolean) {
        this.layoutIsLoading = val;
    }

    async loadTasks(excludeFilters?: boolean) {
        try { 
            this.setTasksIsLoading(true);
            // TODO: Do we need this check?
            if (this.projectsStore.projects) {
                const project = this.tasksGridVisualStore.selectedProject === ALL_PROJECTS ? null : this.tasksGridVisualStore.selectedProject;
                let tasksData: TasksData;
                const sortIndex = this.tasksGridVisualStore.sortOrder.order === 'ascend' ? 1 : -1;
                const resp = await this.tasksService.getTasks(
                    project, 
                    this.tasksGridVisualStore.currentPage, 
                    this.tasksGridVisualStore.pageSize,
                    this.tasksGridVisualStore.searchTerm || null, 
                    this.tasksGridVisualStore.isMainTasksViewEnabled,
                    this.tasksGridVisualStore.isDoneTasksViewEnabled, 
                    !!excludeFilters,
                    sortIndex, 
                    this.tasksGridVisualStore.sortOrder.field);

                resp.map((r) => {
                    tasksData = r;
                    this.setTasks(tasksData);
                    this.tasksFiltersVisualStore.setColumnsFilter(tasksData);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }).mapErr((err: any) => this.errorStore.addError(err.data));
            } else {
                reaction(() => this.projectsStore.isLoading, (p, prev, r) => {
                    if (this.projectsStore.projects) {
                        this.loadTasks();
                        r.dispose();
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    async loadMetadata(projectId: string) {
        this.metadataDefinitionsLoading = true;
        const result = await this.adminService.getMetadataDefinitionsForProject(projectId);
        result.map((r) => {
            runInAction(() => {
                this.metadataDefinitions = r;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
    
        this.metadataDefinitionsLoading = false;
    }

    async loadActionDefinitions() {
        runInAction(() => {
            this.actionDefinitionsLoading = true;
        });

        const resp = await this.actionService.getActionDefinitions();
        resp.map(r => {
            runInAction(() => {
                this.actionDefinitions = r;
            });
        }).mapErr((err) => this.errorStore.addBasicError(err));

        runInAction(() => {
            this.actionDefinitionsLoading = false;
        });
    }

    async loadTaskTypes() {
        this.tasksGlobalViewVisualStore.taskTypesLoading = true;
        const resp = await this.tasksTypesService.getTaskTypesForProject();
        resp.map(t=> {
            runInAction(() => {
                this.taskTypes = t;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
        
        runInAction(() => {
            this.tasksGlobalViewVisualStore.taskTypesLoading = false;
        });
    }

    async loadUsersForProject(projectId: string) {
        this.loadingUsers = true;
        const resp = await this.tasksService.getUsersInProject(projectId);
        resp.map((users: UserProfile[]) => {
            runInAction(() => {
                this.usersInProject = users;
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
        runInAction(() => this.loadingUsers = false);
    }

    async getUserProfilePicture(userId: string) {
        if (userId in this.userProfilePictures) {
            return;
        }
        try {
            runInAction(() => {
                this.userProfilePictures[userId] = undefined;
            });

            const userProfile = await this.tasksService.getUserProfileById(userId);
            if (!userProfile || !userProfile.avatarIconFileId) {
                return;
            }

            const response = await this.tasksService.getUserProfilePicture(userProfile.avatarIconFileId);
            response.map((blob: Blob) => {
                runInAction(() => {
                    this.userProfilePictures[userId] = URL.createObjectURL(blob);
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => this.errorStore.addError(err.data));
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async getTaskStatuses() {
        const resp = await this.tasksService.getTaskStatuses();
        resp.map(r => {
            runInAction(() => {
                this.taskStatuses = r;
            });
        });
    }

    async getTasksByGroupedWidgetId(id: string, projectId: string, page: number, mainTasksOnly: boolean, includeDoneTasks: boolean, sortField: string, sortIndex: string) {
        this.setTasksIsLoading(true);
        const resp = await this.tasksService.getTasksByGroupedWidgetId(id, projectId, 
            page, mainTasksOnly, includeDoneTasks, sortField, sortIndex === 'ascend' ? 1 : -1);
        resp.map(r => {
            runInAction(() => {
                this.setTasks(r);
                this.tasksFiltersVisualStore.setColumnsFilter(r);
                this.tasksGridVisualStore.setCurrentWidgetName(r.widgetName!);
                this.tasksGridVisualStore.forceDoneTasksViewEnabled(r.includeDoneTasks);
            });
        });
    }

    async getTasksByPieChartSection(id: string, projectId: string, value: string, page: number, 
                                    mainTasksOnly: boolean, includeDoneTasks: boolean, sortField: string, sortIndex: string) {
        this.setTasksIsLoading(true);
        this.tasksFiltersVisualStore.unsetAllColumnsFilters();
        const resp = await this.tasksService.getTasksByPieChartSection(id, projectId, value, page, mainTasksOnly, includeDoneTasks, sortField, sortIndex === 'ascend' ? 1 : -1);
        resp.map(r => {
            runInAction(() => {
                this.setTasks(r);
                this.tasksFiltersVisualStore.setColumnsFilter(r);
                this.tasksGridVisualStore.setCurrentWidgetName(r.widgetName!);
                this.tasksGridVisualStore.forceDoneTasksViewEnabled(r.includeDoneTasks);
            });
        });
    }

    async getTasksByActivityType(acitivityType: string,projectId: string, period: string, page: number, 
                                 mainTasksOnly: boolean, includeDoneTasks: boolean, sortField: string, sortIndex: string) {
        this.setTasksIsLoading(true);
        this.tasksFiltersVisualStore.unsetAllColumnsFilters();
        const resp = await this.tasksService.getTasksByActivityType(acitivityType, projectId, period, page, mainTasksOnly, includeDoneTasks, 
            sortField, sortIndex === 'ascend' ? 1 : -1);
        resp.map(r => {
            runInAction(() => {
                this.setTasks(r);
                this.tasksFiltersVisualStore.setColumnsFilter(r);
                this.tasksGridVisualStore.setCurrentWidgetName('Last activities');
            });
        });
    }

    async resetAllFilters() {
        this.setTasksIsLoading(true);
        const projectId = this.selectedProject === ALL_PROJECTS ? null : this.selectedProject;

        const resp = await this.tasksService.resetAllColumnFilters(projectId, this.tasksGridVisualStore.pageSize,
            this.tasksGridVisualStore.currentPage, this.tasksGridVisualStore.searchTerm,
            this.tasksGridVisualStore.isMainTasksViewEnabled, this.tasksGridVisualStore.isDoneTasksViewEnabled);
        resp.map(t => {
            this.setTasks(t);
            this.tasksFiltersVisualStore.unsetAllColumnsFilters();
        }).mapErr(err => this.errorStore.addError(err.data));
        
        this.setTasksIsLoading(false);

    }

    async loadAllUsers() {
        const resp = await this.tasksService.getAllUsers();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resp.map((users: UserProfile[]) => {
            this.userProfiles = users;
            const distinctUsers = users.filter(this.distinctUser);
            runInAction(() => {
                for (const user of distinctUsers) {
                    this.allUsersFullNameResolver[user.userId!] = user.firstName ? `${user.firstName} ${user.lastName}` : `${user.userName}`;
                }
                this.usersLoaded = true;
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async getTaskListLayout() {
        this.setLayoutIsLoading(true);
        const result = await this.tasksService.getTaskListLayoutForUser();
        this.tasksGridVisualStore.setTaskListLayout(result);
        this.setLayoutIsLoading(false);
    }

    async updateColumnFilters(projectId: string | null, filters: ColumnsFilter[]) {
        const resp = await this.tasksService.updateColumnFilters(
            projectId, this.tasksGridVisualStore.pageSize, this.tasksGridVisualStore.currentPage, filters, this.tasksGridVisualStore.searchTerm, 
            this.tasksGridVisualStore.isMainTasksViewEnabled, this.tasksGridVisualStore.isDoneTasksViewEnabled,
            undefined,
            undefined,
            this.tasksGridVisualStore.currentWidgetId,
            this.tasksGridVisualStore.currentWidgetValue
        );
        resp.map(t => {
            this.setTasks(t);
        }).mapErr(err => this.errorStore.addError(err.data));
    }

    async updateField(
        taskId: string,
        fieldName: string,
        value: unknown,
        callback: Function | undefined = undefined,
        updateTasksCallback?: (tasks: TaskListModel[]) => Promise<TaskListModel[]>,
        reloadTasks?: boolean
    ) {
        if (fieldName) {
            let fields: { [fieldName: string]: unknown } = {};
            fields[fieldName] = value || null;
            const tasks = this.tasks;
            // TODO: return updated task and update list
            const resp = await this.tasksService.updateFields(fields, taskId);
            await resp.asyncMap(async () => {
                runInAction(async() => {
                    if (reloadTasks) {
                        this.loadTasks();
                        if (callback) {
                            callback();
                        }
                        return;
                    }
                    let task = tasks.find(t => t.id === taskId);
                    const localFieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                    if (task) {
                        let updatedTasks = tasks.slice();
                        const index = updatedTasks.indexOf(task);
                        updatedTasks[index][localFieldName] = value;

                        if (localFieldName === 'status') {
                            let status = this.taskStatuses.find(s => s.id === value);
                            updatedTasks[index].statusName = status!.name;
                            updatedTasks[index].status = status!.id;
                            // TODO: May be move to callback argument
                            if (task.parentTask) {
                                const parentTaskIndex = tasks.findIndex(t=> t.id === task!.parentTask!.taskId);
                                if (parentTaskIndex) {
                                    const response = await this.tasksService.getTaskById(task!.parentTask!.taskId, null);
                                    response.map(updatedParentTask => {
                                        if (updatedParentTask) {
                                            updatedTasks[parentTaskIndex] = updatedParentTask;
                                        }
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }).mapErr((err: any) => {
                                        this.errorStore.addError(err.data);
                                    });
                                }
                            }
                        }

                        if (['accessType', 'sharedWith'].includes(localFieldName)) {
                            const subtasks = this.tasks.filter(s => s.taskId === taskId);
                            if (subtasks.length) {
                                subtasks.forEach(s => {
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    s[localFieldName] = value as any;
                                });
                            }
                        }
                        if (updateTasksCallback) {
                            updatedTasks = await updateTasksCallback(updatedTasks);
                        }
                        this.setTasksList(updatedTasks);
                    }

                    if (callback) {
                        callback();
                    }
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => this.errorStore.addError(err.data));
        }
    }

    togglePreview(task: TaskListModel | undefined) {
        this.tasksGlobalViewVisualStore.togglePreview(task);
    }

    setPinnedTasks(tasks: PinnedTask[]) {
        this.tasksGlobalViewVisualStore.setPinnedTasks(tasks);
    }

    setAssignedUserInPreview(userId: string, taskIds: string[]) {
        this.tasksGlobalViewVisualStore.setAssignedUserInPreview(userId, taskIds);
    }

    async handlePrivacyChange(task: TaskListModel, value: TaskPrivacyType, sharedWith: string[]) {
        const taskId = task.id;
        if (value !== task.accessType || sharedWith !== task.sharedWith) {
            if (value === 'LimitedAccess') {
                sharedWith = [...sharedWith, this.currentUserId];
                await this.updateField(taskId, 'SharedWith', sharedWith);
                task.sharedWith = sharedWith;
                if (!sharedWith.includes(task.assignedTo)) {
                    await this.updateField(taskId, 'AssignedTo', null);
                }
            }
            await this.updateField(taskId, 'AccessType', value);
            if (value !== 'LimitedAccess') {
                if (task.sharedWith !== null) {
                    await this.updateField(taskId, 'SharedWith', []);
                }
                if (value === 'OnlyMe' && task.assignedTo !== this.currentUserId && task.assignedTo !== null) {
                    await this.updateField(taskId, 'AssignedTo', null);
                }
            }
            task.accessType = value;
        }
    }

    setTasks(tasksData: TasksData) {
        runInAction(() => {
            const { tasks, tasksCount } = tasksData;
            this.tasksCount = tasksCount;
            this.tasks = tasks;
            this.setTasksIsLoading(false);
        });
    }

    setTasksList(tasks: TaskModel[]) {
        this.tasks = tasks;
    }

    unSelectRow(taskId: string) {
        this.tasksGridVisualStore.unSelectRow(taskId);
    }

    removeSubscriptionsFromsGlobalViewStore() {
        this.tasksGlobalViewVisualStore.removeSubscriptions();
    }

    private distinctUser(value: UserProfile, index: number, self: Array<UserProfile>): boolean {
        const user = self.find(u => u.userId === value.userId);
        return self.indexOf(user!) === index;
    }

}