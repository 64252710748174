import { computed, action, observable, runInAction, makeObservable } from 'mobx';
import { ProjectsStore, ErrorStore } from '../../common/stores';
import { TaskService } from '../services';
import { SubtaskFromTemplates, TaskModel } from '../types';
import { RcFile } from 'antd/lib/upload/interface';
import { GlobalAdministrationService } from '../../administration/services/GlobalAdministrationService';
import { MetadataDefinition } from '../../administration/types/Metadata';
import { ALL_PROJECTS } from '../screens/TasksPage';
import TasksRootStore from './TasksRootStore';
import { TaskType } from '../../task_types/types';
import { UserProfile } from '../../common/services/types';
import TaskTypesService from '../../task_types/services/TaskTypesService';
import dayjs, { Dayjs } from 'dayjs';
export default class TaskCreateVisualStore {
    newTaskDialogVisible: boolean = false;

    taskAttachments: RcFile[] = [];

    // @observable
    // taskBaseMsgFile: RcFile | undefined = undefined;

    // @observable
    // useMsgToCreateTask: boolean = false;

    usersInProject: UserProfile[] = [];

    taskCreatingInProgress: boolean = false;

    taskTypes: TaskType[] = [];

    taskTypesLoading: boolean = false;
          
    assignToUsers: UserProfile[] = [];

    isUsersLoading: boolean = false;

    metadata: MetadataDefinition[] = [];

    currentMetadataKey: {[key: number]: MetadataDefinition} = {};

    subtasksFromTemplate: SubtaskFromTemplates[] = [];

    currentTaskType: string | null;

    paramsSavedInUi: boolean = false;
    
    constructor(private projectsStore: ProjectsStore, private errorStore: ErrorStore, private tasksService: TaskService, 
                private taskTypesService: TaskTypesService, private adminService: GlobalAdministrationService, private taskRootStore: TasksRootStore) {
        makeObservable<TaskCreateVisualStore>(this, {
            newTaskDialogVisible: observable,
            taskAttachments: observable,
            usersInProject: observable,
            taskCreatingInProgress: observable,
            taskTypes: observable,
            taskTypesLoading: observable,
            assignToUsers: observable,
            isUsersLoading: observable,
            currentMetadataKey: observable,
            metadata: observable,
            currentTaskType: observable,
            currentProjectId: computed,
            hasFiles: computed,
            filteredAssignToUsers: computed,
            projects: computed,
            filteredMetadata: computed,
            setNewTaskDialogVisible: action.bound,
            setTaskAttachments: action.bound,
            loadUsersForProject: action.bound,
            loadTaskTypesForProject: action.bound,
            saveTask: action.bound,
            setFilteredAssignToUsers: action.bound,
            setCurrentMetadataKey: action.bound,
            removeMetadataKey: action,
            setCurrentTaskType: action
        });
    }

    get currentProjectId() {
        return this.taskRootStore.selectedProject === ALL_PROJECTS ? this.projectsStore.currentProjectId : this.taskRootStore.selectedProject;
    }

    get filteredProjectId() {
        const projectId = this.taskRootStore.selectedProject;
        return projectId === ALL_PROJECTS ? '' :  projectId;
    }

    get hasFiles() {
        return this.taskAttachments && this.taskAttachments.length > 0;
    }

    get filteredAssignToUsers() {
        if (this.assignToUsers.length) {
            return this.usersInProject.filter(u => this.assignToUsers.map(x=> x.userId).includes(u.userId) && !u.isDeleted);
        }
        return this.usersInProject.filter(u=> !u.isDeleted);
    }

    get projects() {
        return this.projectsStore.projectsForTasks;
    }

    get filteredMetadata() {
        return this.currentTaskType ? this.metadata.filter(m=> m.taskTypes?.length ? m.taskTypes.includes(this.currentTaskType!) : true): this.metadata;
    }

    getMetadataById(id: string) {
        return this.metadata.find(m=> m.id === id);
    }


    // Test path for hiding dialog
    setNewTaskDialogVisible(visible: boolean) {
        this.newTaskDialogVisible = visible;
        if (!visible) {
            this.taskAttachments = [];
            this.setFilteredAssignToUsers([]);
        }
    }

    setTaskAttachments(taskAttachments: RcFile[]) {
        this.taskAttachments = taskAttachments;
    }

    async loadProjects() {
        this.projectsStore.loadProjectsForTask();
    }

    async loadMetadata(projectId?: string) {
        const resp = await this.adminService.getMetadataDefinitionsForProject(projectId || this.currentProjectId);
        resp.map((metadata) => {
            runInAction(() => {
                this.metadata = metadata;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    // @action.bound
    // setTaskMsgBaseFile(file: RcFile | undefined) {
    //     this.taskBaseMsgFile = file;
    // }

    // @action.bound
    // setUseMsgToCreateTask (useMsg: boolean) {
    //     this.useMsgToCreateTask = useMsg;
    // }

    async loadUsersForProject(projectId: string) {
        this.isUsersLoading = true;
        const resp = await this.tasksService.getUsersInProject(projectId);
        resp.map((users: UserProfile[]) => {
            runInAction(() => {
                this.usersInProject = users;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
        runInAction(() => {
            this.isUsersLoading = false;
        });
    }

    // TODO: Test all paths
    async loadTaskTypesForProject(projectId: string) {
        runInAction(() => {
            this.taskTypes = [];
            this.taskTypesLoading = true;
        });
        const resp = await this.taskTypesService.getTaskTypesForProject(projectId);
        resp.map(t=> {
            runInAction(() => {
                this.taskTypes = t;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
        runInAction(() => {
            this.taskTypesLoading = false;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async saveTask(taskFormValues: any) {
        try {
            runInAction(() => {
                this.taskCreatingInProgress = true;
            });

            taskFormValues.subtasksFromTemplate = this.subtasksFromTemplate.map(s=> ({...s, description: s.description ?? ''}));
            var keys = Object.getOwnPropertyNames(taskFormValues);
            var formData = new FormData();
            for (let key of keys) {
                if (taskFormValues[key]) {
                    const value = taskFormValues[key];
                    if (dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                        formData.append(key, (value as Dayjs).format());
                        continue;
                    }
                    if (Array.isArray(value)) {
                        value.forEach((el, index) => {
                            if (typeof el === 'object') {
                                Object.keys(el).forEach((k) => {
                                    formData.append(`${key}[${index}].${k}`, el[k]);
                                });
                            } else {
                                formData.append(key, el);
                            }
                        });
                        continue;
                    } 
                    if (typeof value === 'object') {
                        if (Object.values(value).some(x=> x !== undefined)) {
                            formData.append(key, JSON.stringify(value));
                        } else {
                            continue;
                        }
                    } else {
                        formData.append(key, value);
                    }
                }
            }    
            if (this.taskAttachments && this.taskAttachments.length) {
                for (var attachment of this.taskAttachments) {
                    formData.append('attachments', attachment);
                }
            }
            
            const resp = await this.tasksService.createNewTask(formData);
            resp.map(() => {
                runInAction(() => {
                    this.taskCreatingInProgress = false;
                    this.setNewTaskDialogVisible(false);
                });
                
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                this.errorStore.addError(err.data);
                runInAction(() => {
                    this.taskCreatingInProgress = false;
                });
                return null;
            });
            const id = resp.unwrapOr(null);
            if (id) {
                const createdTask = await this.getTaskById(id);
                return createdTask;
            }
            return null;
            
        } catch (err) {
            this.errorStore.addBasicError(err);
            runInAction(() => {
                this.taskCreatingInProgress = false;
            });
            return null;
        }
    }

    setFilteredAssignToUsers(vals: string[]) {
        this.assignToUsers = vals.map(x=> this.usersInProject.find(y=> y.userId === x)!);
    }

    setCurrentMetadataKey(key: number, id: string | undefined) {
        if (id) {
            const metadata = this.getMetadataById(id)!;
            Object.assign(this.currentMetadataKey[key] = metadata);
        } else {
            delete this.currentMetadataKey[key];
        }
    }

    clearCurrentMetadata() {
        this.currentMetadataKey = {};
    }

    removeMetadataKey(key: number) {
        delete this.currentMetadataKey[key];
    }

    setSubtasksFromTemplate(subtasks: SubtaskFromTemplates[]) {
        this.subtasksFromTemplate = subtasks;
    }

    setCurrentTaskType(taskType: string | null) {
        this.currentTaskType = taskType;
    }

    setParamsSavedInUi(isSaved: boolean) {
        this.paramsSavedInUi = isSaved;
    }

    private async getTaskById(id: string): Promise<TaskModel | null> {
        const resp = await this.tasksService.getTaskById(id, null);
        return resp.unwrapOr(null);
    }
}