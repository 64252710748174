import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Dropdown, Button, Popconfirm, Skeleton } from 'antd';
import { TaskViewVisualStore } from '../stores';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { PackagePreviewModel } from '../types';
import { ButtonType } from 'antd/lib/button';
import { ItemType, MenuItemType } from 'antd/lib/menu/interface';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { AuthConsumer } from '../../authorization/AuthContext';
import { ProjectRole } from 'src/modules/administration/types/UserModel';

type Props = {
    tasksStore: TaskViewVisualStore;
    taskId: string;
    disabled: boolean;
    onDownload: (attachmentId: string | undefined) => void;
    onPreview: (attachmentId: string | undefined) => void;
    onSendTaskToUsers?: () => void;
    onRemoveAttachements: () => void;
    onRemoveTask?: () => void;
    label?: string | JSX.Element;
    type?: ButtonType | undefined | 'ghost';
    size?: SizeType;
    btnStyle?: React.CSSProperties;
    pkg?: PackagePreviewModel;
    selectedPackages?: PackagePreviewModel[];
    isTaskMenu?: boolean
};

const TaskApplicationsMenu: React.FC<Props> = ({
    tasksStore,
    label,
    btnStyle,
    disabled,
    onPreview,
    onDownload,
    onSendTaskToUsers,
    onRemoveAttachements,
    onRemoveTask,
    size = undefined,
    type = 'primary',
    isTaskMenu = true,
    pkg = undefined}) => {

    const availableActions = pkg ? tasksStore.getAvailableAttachmentActions(pkg) : [];

    const handleExecuteActionClick = (actionId: string) => {
        const action = availableActions
            .find(x => x.action.id === actionId);
        const hasUserInputFields = action!.action.fields
            .some(x => x.fieldType === 'UserInput') || false;

        if (hasUserInputFields) {
            // Display form
            tasksStore.setSelectedAction(actionId, pkg?.id);
        } else {
            // Execute action
            tasksStore.executeAction(tasksStore.taskPreview!.id, actionId, {}, action!.action.isClientSide, pkg?.id)
                .catch(err => console.log(err));
        }
    };
    
    const mainAppsMenu = {
        // <Menu.Item key="m1">Make dependent </Menu.Item>,
        // <Menu.Item key="m2">Add tags</Menu.Item>,
        // <Menu.Item key="m3">Follow-up task</Menu.Item>,
        // <Menu.Item key="m4">Duplicate task</Menu.Item>,
        key: 1,
        label: (
            <div 
                data-id-type="popup-menu-list-item"
                key="m5"
                onClick={onSendTaskToUsers}
            >
                Email task to...
            </div>
        ),
        disabled: !onSendTaskToUsers
    };

    const disabledForPreview = (fileName: string) => {
        return !['.pdf', '.xml', '.docx', '.xlsx', '.xlsm', 'txt', 'jpg', 'png'].some(ext=> fileName.toLocaleLowerCase().endsWith(ext));
    };

    const disabledForRemove = () => {
        const {status} = tasksStore.taskPreview!;
        const isFinalStatus = !!tasksStore.getStatusById(status)?.isFinal;
        return isFinalStatus;
    };

    const getPreviewMenu = (fileName: string) => {
        return ({
            key: 1,
            label: (
                <div 
                    data-id-type="popup-menu-list-item"
                    key={pkg ? pkg.id + '1' : 'p1'} 
                    onClick={() => onPreview(pkg!.id!)}
                >
                    Preview
                </div>
            ),
            disabled : disabledForPreview(fileName)
        });
    };

    const getActionsMenu = (attachment: PackagePreviewModel) => {
        let items = [] as ItemType<MenuItemType>[];
        if (tasksStore.availableActionsLoading) {
            items.push({ key: 'loading', label: (<Skeleton.Button active size="small" block />)});
        }
       
        items = items.concat(availableActions
            .sort((a, b) => a.action.position - b.action.position)
            .map(x => ({
                key: x.action.id,
                label: (
                    <div 
                        onClick={() => handleExecuteActionClick(x.action.id)}
                    >
                        {x.action.title ?? x.action.name}
                    </div>
                ),
                disabled: !x.canExecute
            })));
        if (tasksStore.availableActionsLoading || availableActions.length > 0) {
            items.push({ key: 'divider', label: (<Menu.Divider data-package-id={attachment.id} />)});
        }
        return items;
    };

    const getApplicationsMenu = (permissions: string[], projectRoles: ProjectRole[]) => {
        let items = [] as ItemType<MenuItemType>[];
        if (pkg?.id) {
            items = items.concat(getActionsMenu(pkg));
        }
        if (!pkg) {
            items.push(mainAppsMenu);
        }
        if (pkg?.id) {
            items.push(getPreviewMenu(pkg.fileName));
        }
        items = items.concat([
            {

                key: 'download',
                label: (
                    <div 
                        data-id="task-download-item"
                        data-id-type="popup-menu-list-item"
                        key="task-download-item"
                        onClick={() => onDownload(pkg?.id)}
                    >
                        {isTaskMenu ? 'Download attachments' : 'Download'}
                    </div>
                ),
                disabled: disabled
            },
            {
                key: 'remove_attachment',
                label: (
                    <div 
                        data-id="task-remove-item"
                        data-id-type="popup-menu-list-item"
                        key="task-remove-item"
                        onClick={onRemoveAttachements}
                        style={{ color: disabled ? '#00000040' : '#B80C02' }}
                    >
                        Remove {pkg ? '' : 'attachments'}
                    </div>
                ),
                disabled: disabled || disabledForRemove()
            }
        ]);

        if (isTaskMenu) {
            items.push(
                {
                    key: 'link',
                    label: (
                        <div 
                            data-id="task-add-link"
                            data-id-type="popup-menu-list-item"
                            key="task-add-link"
                            onClick={() => tasksStore.setSelectedLink(null)}
                        >
                            Link task
                        </div>
                    ),
                    disabled: tasksStore.taskFormLocked
                }
            );
        }

        if (onRemoveTask && (hasPermission(permissions, AppPermissions.CanAccessAdministration, projectRoles, tasksStore.taskPreview!.projectId ))){
            items.push(
                {
                    key: 'remove_task',
                    label: (
                        <div 
                            data-id="task-delete-item"
                            key="task-delete-item"
                            style={{ color: 'red' }}
                        >
                            <Popconfirm
                                title="Are you sure that you want to delete this task?"
                                okText="Delete"
                                onConfirm={onRemoveTask}
                                cancelText="Cancel"
                            >
                                Delete task
                            </Popconfirm>
                        </div>
                    ),
                    disabled: tasksStore.taskFormLocked
                }
            );
        }
        return {items};
    };

    // packageId ? false : !tasksStore.canRunApplications
    return (
        <AuthConsumer>
            {({ permissions, projectRoles }) => (
                <Dropdown
                    data-id="new-contract-new-dropdown"
                    menu={getApplicationsMenu(permissions, projectRoles)}
                    placement="topLeft"
                    trigger={['click']}
                >
                    <Button
                        data-id="new-contract-new-dropdown-button"
                        type={type === 'ghost' ? undefined: type}
                        ghost={type === 'ghost'}
                        size={size}
                        style={btnStyle}
                    >
                        {label || 'New...'}
                    </Button>
                </Dropdown>)}
        </AuthConsumer>
    );
};

export default observer(TaskApplicationsMenu);